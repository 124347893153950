
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import ListPdfHighlight, {
  getPDFHighlight,
  IHighlightPdf,
  deleteFile,
} from "./pdfHighlight";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

export default defineComponent({
  name: "highlight-pdf",
  components: {
    ErrorMessage,
    Datatable,
    Field,
    Form,
  },
  setup() {
    const forms = ref(null);
    const submitButton1 = ref<HTMLElement | null>(null);
    const fileInput = ref<HTMLElement | null>(null);
    const tableData = ref<Array<IHighlightPdf>>(ListPdfHighlight);
    const FormDisplay = ref(false);
    const tableKey = ref(0);
    const total_data = ref(0);
    const is_loading = ref(true);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const file_pdf = ref("");
    const filename = ref("");
    const filenamePDF = ref("");
    const validator = Yup.object().shape({
      pdf: Yup.string().required().label("Nama Koran"),
      uploadfile: Yup.string().required().label("File PDF"),
    });

    const tableHeader = ref([
      {
        name: "Filename",
        key: "filename",
        sortable: true,
      },
      {
        name: "Path",
        key: "nama_file",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Aksi",
        key: "aksi",
        sortable: false,
      },
    ]);

    const onFileChange = (e: any) => {
      const file = e.target.files[0];
      const fileSize = e.target.files[0].size / 1024 / 1024;
      if (file) {
        if (fileSize <= 50) {
          file_pdf.value = file;
          filenamePDF.value = file.name;
        } else {
          Swal.fire({
            title: "File terlalu besar",
            text: "File yang diupload harus lebih kecil dari 50 MB",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    };

    const saveChanges1 = (values, actions) => {
      if (submitButton1.value) {
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        submitButton1.value.setAttribute("disabled", "disabled");
        let formdata = new FormData();
        file_pdf.value = values.uploadfile[0];
        formdata.append("file", values.filepdf[0]);
        formdata.append("filename", values.pdf);
        // console.log(values, formdata);
        // file_pdf.value = "";

        axios
          .post(`${process.env.VUE_APP_API_URL}/files/upload`, formdata, {
            headers: {
              Authorization: `Bearer ${JwtService.getToken()}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: "File Berhasil Di Upload",
              icon: "success",
              confirmButtonText: "Ok",
            });
            FormDisplay.value = false;
            filename.value = "";
            file_pdf.value = "";
            submitButton1.value?.removeAttribute("data-kt-indicator");
            submitButton1.value?.removeAttribute("disabled");
            actions.setValues({
              pdf: "",
              uploadfile: "",
              filepdf: "",
            });

            fetchPDFHighlight();
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Error",
              text: "File Gagal Di Upload",
              icon: "error",
              confirmButtonText: "Ok",
            });
            submitButton1.value?.removeAttribute("data-kt-indicator");
            submitButton1.value?.removeAttribute("disabled");
            actions.setValues({
              pdf: "",
              uploadfile: "",
              filepdf: "",
            });

            // fileInput.value?.removeAttribute("value");
          });
      }
    };

    const fetchPDFHighlight = async () => {
      is_loading.value = true;
      const res = await getPDFHighlight();
      tableData.value = res.data;
      total_data.value = tableData.value.length;
      is_loading.value = false;
      tableKey.value += 1;
    };

    const goToRoute = (value) => {
      let url = value.replace("/var/www/", "https://");
      window.open(url, "_blank");
    };

    const deleteFiles = (id) => {
      Swal.fire({
        title: "Apakah anda yakin ingin mengahapus file tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await deleteFile(id);
          if (res.status == 200) {
            Swal.fire("Deleted!", "", "success");
            fetchPDFHighlight();
          } else {
            Swal.fire("Gagal!", "", "warning");
          }
        }
      });
    };

    onMounted(() => {
      setCurrentPageTitle("Highlight PDF");
      fetchPDFHighlight();
    });
    return {
      FormDisplay,
      tableKey,
      total_data,
      is_loading,
      itemsPerPage,
      currentPage,
      tableHeader,
      tableData,
      goToRoute,
      deleteFiles,
      validator,
      saveChanges1,
      submitButton1,
      file_pdf,
      filename,
      fileInput,
      onFileChange,
      forms,
      filenamePDF,
    };
  },
});
